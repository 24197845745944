<template>
  <!-- 听写 -->
  <div class="dictation">
    <el-button
      v-if="!$store.state.examination"
      class="btn"
      type="primary"
      @click="handleClick"
      >{{ btntext }}</el-button
    >
    <div class="score" v-if="$store.state.examination">
      <span>{{ value ? value.score : "" }}</span>
      <p>得分：{{ value ? value.right : "" }}</p>
      <p>扣分：{{ value ? value.error : "" }}</p>
      <p>得分率：{{ value ? value.accuracy : "" }}%</p>
      <p>耗时：{{ value ? value.elapsed : "" }}分钟</p>
    </div>
    <el-input
      class="dictation-textarea"
      type="textarea"
      placeholder="此处写正文"
      v-model="Content"
      :disabled="isStart"
      @input="change"
    >
    </el-input>
    <p v-show="$store.state.examination" class="ai_result">
      {{ ai_result }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    examinee_id: {
      type: [Number, String],
      default: 0,
    },
   
  },
  data() {
    return {
      Content: "",
      btntext: "开始答题",
      timeData: 0,
      timer: null,
      isStart: true,
    };
  },
  computed: {
    ai_result() {
      return this.value?.questions ? this.value.questions[0].ai_result : "";
    },
  },
  // created() {
  //   if (this.value?.questions) {
  //     this.Content = this.value?.questions[0]?.preset_text;
  //     if (this.value?.questions[0].my_answer) {
  //       this.Content = this.value.questions[0].my_answer;
  //     }
  //   }
  // },
  watch: {
    value: {
      handler(val, oldval) {
        this.Content =
          this.$store.state.dictationText != ""
            ? this.$store.state.dictationText
            : val?.questions[0]?.preset_text;
        if (this.examinee_id == 0) return;
        if (val?.questions[0].my_answer) {
          this.Content =
            this.$store.state.dictationText != ""
              ? this.$store.state.dictationText
              : val?.questions[0]?.my_answer;
        }
      },
      deep: true,
    },
  },
  methods: {
    change(e) {
      this.$store.commit("setDictationText", e);
    },
    handleClick() {
      this.$emit("next", !this.isStart);
      this.isStart = !this.isStart;
      if (!this.isStart) {
        this.timer = setInterval(() => {
          this.timeData++;
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.timer = null;

        this.$emit("changeContent", [
          {
            question_id: this.value.questions[0].id,
            my_answer: this.Content,
          },
        ]);

        this.$emit("endTime", {
          type_code: this.value.type_code,
          elapsed: Math.round(this.timeData / 60),
        });
      }
      this.btntext = this.isStart ? "开始答题" : "结束答题";
    },
  },
};
</script>

<style lang="less" scoped>
.dictation {
  position: relative;
  padding: 5px 20px 20px;
  border: 2px solid #ccc;
  height: 92%;
  width: 94%;
  border-radius: 20px;
  overflow: auto;
  &-textarea {
    margin-top: 1%;
    width: 100%;
    height: 93%;
    ::v-deep textarea {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 15px;
      font-size: 22px;
    }
  }
}
.ai_result {
  position: absolute;
  bottom: 2%;
  left: 4%;
  color: red;
  font-size: 23px;
}
.score {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 220px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  z-index: 10;
  background: #fff;
  span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 50px;
    font-style: italic;
  }
  p {
    font-size: 22px;
  }
}
@media (max-width: 640px) {
  .dictation-textarea {
    width: 98%;
    height: 83%;
  }
  .score {
    width: unset;
    padding: 10px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>