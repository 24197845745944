import { render, staticRenderFns } from "./makeUp.vue?vue&type=template&id=7fe12c40&scoped=true"
import script from "./makeUp.vue?vue&type=script&lang=js"
export * from "./makeUp.vue?vue&type=script&lang=js"
import style0 from "./makeUp.vue?vue&type=style&index=0&id=7fe12c40&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fe12c40",
  null
  
)

export default component.exports