import { render, staticRenderFns } from "./pack.vue?vue&type=template&id=31f70063&scoped=true"
import script from "./pack.vue?vue&type=script&lang=js"
export * from "./pack.vue?vue&type=script&lang=js"
import style0 from "./pack.vue?vue&type=style&index=0&id=31f70063&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f70063",
  null
  
)

export default component.exports