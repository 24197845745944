<template>
  <div style="height: 99%; width: 100%" v-loading="ScantronLoading">
    <div id="adobe-dc-view">
      <template v-if="paperData.showAnalysis">
        <template v-for="(jpg) in paperData.analysis_pdf_images">
          <img :src="jpg" style="width:100%;">
          <hr/>
        </template>
      </template>
      <template v-else>
        <template v-for="(jpg) in paperData.questions_pdf_images">
          <img :src="jpg" style="width:100%;">
          <hr/>
        </template>
      </template>
    </div>
    <div :class="['Scantron', moveClass]">
      <div class="left" @click="handleClick('toleft')">
        <img src="../../assets/show2.png" alt="" />
      </div>
      <div class="contain">
        <div v-if="!$store.state.examination" :class="['ifream-boxs', isUp ? 'toUp' : 'toDown']">
          <img :class="[isUp ? 'isUp' : '']" src="../../assets/Frame.png" alt="" @click="handleUp" />
          <div id="adobe-dc2-view">
            <template v-for="(jpg) in paperData.questions_pdf_images">
              <img :src="jpg" style="width:100%;">
              <hr/>
            </template>
          </div>
        </div>
        <!-- 头部 -->
        <div class="top">
          <div :class="['box', moveClass == 'toleft' ? '' : 'boxs']">
            <div class="time" v-if="moveClass == 'toleft'">
              <img src="../../assets/时间.png" alt="" v-if="!$store.state.examination" />
              <FlipDown v-if="!$store.state.examination && paperData.max_time" :endDate="paperData.max_time" :type="3"
                :theme="2" :timeUnit="[':', ':', ':']" @timeUp="timeEnd(btnText)" />
            </div>
            <div class="title">{{ paperData.title }}</div>
            <div class="rights">
              <div class="btn" @click="handleSubmit(btnText)">
                {{ btnText }}
              </div>
              <span v-if="$store.state.examination">{{
                paperData.user_score
              }}</span>
            </div>
          </div>
        </div>
        <!-- 答题内容 -->
        <div class="bottom">
          <div class="btns">
            <div class="item" v-for="(item, i) in dataList" :key="i" @click="handleCheck(item.type_code)">
              {{ item.total_score }}
              <div :class="[
                'score',
                activeIndex == item.type_code ? 'active' : '',
              ]">
                {{ item.type_name }}
              </div>
            </div>
          </div>
          <div class="anwser" v-loading="loading">
            <!-- 写作 -->
            <Write v-show="activeIndex.startsWith('writing-writing')" v-model="questionsObjext"
              :examinee_id="$route.query.examinee_id" :states="stateType" @changeContent="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 听力 -->
            <Hearing v-model="questionsObjext" v-show="activeIndex.startsWith('listening')"
              :examinee_id="$route.query.examinee_id" :states="stateType" :audioList="paperData.audioList"
              @handleChange="changeContents" @endTime="handleEndTime" @next="handleNext" />
            <!-- 判断题 -->
            <Read v-model="questionsObjext" v-show="activeIndex.startsWith('true_or_false')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @handleChange="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 阅读 -->
            <Read v-model="questionsObjext" v-show="activeIndex.startsWith('reading')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @handleChange="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 翻译 -->
            <Translate v-model="questionsObjext" v-show="activeIndex.startsWith('translation')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @changeContent="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 听写 -->
            <Dictation v-model="questionsObjext" v-show="activeIndex.startsWith('dictation')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @changeContent="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 作补 -->
            <MakeUp v-model="questionsObjext" v-show="activeIndex.startsWith('writing-complete')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @changeContent="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 作答 -->
            <Answer v-model="questionsObjext" v-show="activeIndex.startsWith('writing-answer')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @changeContent="changeContents"
              @endTime="handleEndTime" @next="handleNext" />

            <!-- 听选 -->
            <!-- <Read v-model="questionsObjext" v-show="activeIndex == '听选'" :examinee_id="$route.query.examinee_id"
              :states="stateType" @handleChange="changeContents" @endTime="handleEndTime" @next="handleNext" /> -->
            <!-- 阅选 -->
            <!-- <Read v-model="questionsObjext" v-show="activeIndex == '阅选'" :examinee_id="$route.query.examinee_id"
              :states="stateType" @handleChange="changeContents" @endTime="handleEndTime" @next="handleNext" /> -->

            <!-- 填空 -->
            <Pack v-model="questionsObjext" v-show="activeIndex.startsWith('close')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @handleChange="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 拼写 -->
            <Pack v-model="questionsObjext" v-show="activeIndex.startsWith('spell')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @handleChange="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
            <!-- 改错 -->
            <Mistake v-model="questionsObjext" v-show="activeIndex.startsWith('correct')"
              :examinee_id="$route.query.examinee_id" :states="stateType" @changeContent="changeContents"
              @endTime="handleEndTime" @next="handleNext" />
          </div>
        </div>
      </div>
      <div class="right" @click="handleClick('toright')">
        <img src="../../assets/hide2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getPaper, getQuestions, postExaminee } from "../../api/index";
import FlipDown from "../../components/FlipDown.vue";
import Write from "../components/write.vue";
import Hearing from "../components/hearing.vue";
import Read from "../components/read.vue";
import Translate from "../components/translate.vue";
import Dictation from "../components/dictation.vue";
import MakeUp from "../components/makeUp.vue";
import Answer from "../components/answer.vue";
import Pack from "../components/pack.vue";
import Mistake from "../components/mistake.vue";
export default {
  components: {
    FlipDown,
    Write,
    Hearing,
    Read,
    Translate,
    Dictation,
    MakeUp,
    Answer,
    Pack,
    Mistake,
  },
  data() {
    return {
      btnText: "交卷",
      moveClass: "toleft",
      isUp: false,
      dataList: [],
      activeIndex: "",
      canNext: true,
      paperData: {
        total_score: "",
        title: "",
        questions_pdf: "",
        max_time: 0,
        examinee_id: "",
        paper_id: "",
        analysis_pdf: "",
        questions_pdf_images: null,
        analysis_pdf_images: null,
        showAnalysis: false,
      },
      answers: [],
      types_elapsed: [],
      questionsObjext: null,
      loading: false,
      ScantronLoading: false,
      max_time: null,
      stateType: null,
    };
  },
  created() {
    this.stopF5Refresh();
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  },
  mounted() {
    document.getElementById('app').style.overflow = 'scroll';
    window.addEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });

    let usingPdf = false;
    if(usingPdf) {
      if (window.AdobeDC) {
        this.paperList(true);
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          this.paperList(true);
        });
      }
    }
    else {
      this.paperList(false);
    }
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });
  },

  methods: {
    // 获取试卷
    async paperList(need) {
      const res = await getPaper({
        user_id: Number(this.$route.query.user_id), //用户id
        pid: this.$route.query.pid, //合作方id
        paper_id: Number(this.$route.query.paper_id), //试卷id
        examinee_id: Number(this.$route.query.examinee_id), //考试场次id：0=则发起一次新的答题；>0表示继续某次未完成的答题
      });
      if (res.code == 0) {
        console.log('page/HomeView.vue: getPaper:', res);
        this.paperData.title = res.data.title;
        this.paperData.questions_pdf = res.data.questions_pdf;
        this.paperData.examinee_id = res.data.examinee_id;
        this.paperData.paper_id = res.data.paper_id;
        this.paperData.analysis_pdf = res.data.analysis_pdf;
        this.max_time = res.data.max_time;
        this.stateType = res.data.status;
        this.paperData.user_score = res.data.user_score
        this.paperData.questions_pdf_images = res.data.questions_pdf_images;
        this.paperData.analysis_pdf_images  = res.data.analysis_pdf_images;

        const obj = res.data.question_types.find(
          (item) => item.type_code.indexOf('listening')==0
        );
        if (obj) {
          this.paperData.audioList = obj.music_mp3;
        }

        if (this.stateType == "done") {
          this.btnText = "总分";
          this.$store.commit("setExamination", true);
        }

        const times = new Date();
        this.paperData.max_time = new Date(
          times.getTime() + this.max_time * 60 * 1000
        );
        if (need) {
          this.showPDF("adobe-dc-view", this.paperData.questions_pdf);
          !this.$store.state.examination &&
            this.showPDF("adobe-dc2-view", this.paperData.questions_pdf);
        }

        this.dataList = res.data.question_types;

        this.handleCheck(
          this.dataList[0].type_code
        );
      } else {
        this.$message.error("试卷获取失败 "+(res.msg||''));
      }
    },

    // pdf展示
    showPDF(id, url) {
      if(window.AdobeDC) {
        console.log(id, url);
        const adobeDCView = new AdobeDC.View({
          clientId: "81211a7dfbe741499e88a71fbe4c8c48",
          divId: id,
        });
        adobeDCView.previewFile({
          content: {
            location: {
              url: url,
            },
          },
          metaData: { fileName: "北京奇峰天下科技有限公司技术支持.pdf" },
        });
      }
    },

    // 答题卡左右滑动
    handleClick(type) {
      this.moveClass = type;
    },

    // 点击题型获取题目
    async handleCheck(type_code) {
      if (this.activeIndex === type_code && !this.$store.state.examination) return;
      if (this.canNext) {
        this.loading = true;
        this.activeIndex = type_code;
        const res = await getQuestions({
          examinee_id: Number(this.paperData.examinee_id), //考试场次id
          paper_id: Number(this.paperData.paper_id), //试卷id
          type_code: type_code, //待获取的题型代码
        });
        this.questionsObjext = res.data;
        this.loading = false;
      } else {
        this.$message.warning("请先结束上一题作答");
      }
    },

    // 获取/修改 每大题答题时间
    handleEndTime(val) {
      console.log(val);
      const i = this.types_elapsed.findIndex(
        (item) => item.type_code === val.type_code
      );
      if (i >= 0) {
        this.types_elapsed.splice(i, 1);
      }
      this.types_elapsed.push(val);
    },

    // 获取/修改 每道题目的答案
    changeContents(val) {
      val.forEach((item) => {
        const i = this.answers.findIndex(
          (items) => items.question_id === item.question_id
        );
        if (i >= 0) {
          this.answers[i].my_answer = item.my_answer;
        } else {
          this.answers.push(item);
        }
      });
      console.log(this.answers);
    },

    // 提交试卷
    handleSubmit(type) {
		console.dir(this.answers)
      if (!this.canNext) return this.$message.warning("请先结束题目作答");
      if (type === "交卷") {
        this.$confirm("是否确认提交试卷", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            this.timeEnd();
          })
          .catch(() => { });
      }
    },
    async timeEnd() {
      this.ScantronLoading = true;
	    console.dir(this.answers)
      // 提交试卷请求
      const res = await postExaminee({
        user_id: Number(this.$route.query.user_id), //用户id, //用户id
        pid: this.$route.query.pid, //合作方id
        examinee_id: Number(this.paperData.examinee_id), //考试场次id
        paper_id: Number(this.paperData.paper_id), //试卷id
        answers: this.answers,
        types_elapsed: this.types_elapsed,
      });
      if (res.code == 0) {
        this.ScantronLoading = false;
        this.btnText = "总分";
        this.$store.commit("setExamination", true);
        this.paperData.user_score = res.data.total_score.type_score;
        this.handleCheck(
          this.dataList[0].type_code
        );
        this.paperData.showAnalysis = true;
        let bShowImage = this.paperData.analysis_pdf_images && this.paperData.analysis_pdf_images.length > 0;
        if(!bShowImage) {
          this.showPDF("adobe-dc-view", this.paperData.analysis_pdf);
        }
      } else {
        this.ScantronLoading = false;
        this.$message.error("提交失败请重新提交");
      }
    },
    // 答题卡内部ifream移动
    handleUp() {
      this.isUp = !this.isUp;
    },

    // 做下一题
    handleNext(val) {
      this.canNext = val;
    },

    // 禁止刷新
    stopF5Refresh() {
      document.onkeydown = function (e) {
        var evt = window.event || e;
        var code = evt.keyCode || evt.which;
        //屏蔽F1---F12
        if (code > 111 && code < 123) {
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false;
          }
        }
      };
      //禁止鼠标右键菜单
      document.oncontextmenu = function () {
        return false;
      };
      //阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
      history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, window.location.href);
      });
    },

    beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = "您是否确认离开此页面-您输入的数据可能不会被保存";
      }
      return "您是否确认离开此页面-您输入的数据可能不会被保存";
    },
  },
};
</script>
<style lang="less" scoped>
.test-clock-container {
  font: normal 14px "Helvetica Neue", Helvetica, sans-serif;
  user-select: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  background: radial-gradient(ellipse at center, #969696 0%, #595959 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Scantron {
  position: fixed;
  z-index: 1000;
  top: 10%;
  left: 89%;
  // height: 949px;
  // width: 1867px;
  height: 80%;
  width: 80%;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 20px black;
  display: flex;
  border: 3px solid #575757;

  // padding-left: 50px;
  .contain {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 96%;
    padding: 28px 0 0;

    .top {
      border-bottom: 3px solid rgb(237, 2, 140);
      padding-bottom: 6px;

      .box {
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 3px dashed rgb(237, 2, 140);

        .time {
          display: flex;
          align-items: center;

          img {
            width: 90px;
            height: 90px;
            margin-right: 10px;
          }
        }

        .title {
          font-size: 45px;
          font-weight: 600;
          color: rgb(237, 2, 140);
          text-align: center;
          width: 260px;
        }

        .rights {
          display: flex;
          align-items: center;

          .btn {
            cursor: pointer;
            width: 180px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            color: #fff;
            background-color: rgb(237, 2, 140);
            border-radius: 10px;
            font-size: 30px;
            font-weight: 600;
          }

          span {
            margin-left: 25px;
            display: inline-block;
            font-size: 66px;
            text-decoration: underline;
            font-style: italic;
          }
        }
      }

      .boxs {
        padding: 0;
      }
    }

    .bottom {
      height: 80%;
      display: flex;

      .btns {
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
          display: none;
          /* Chrome Safari */
        }

        .item {
          margin-top: 50px;
          margin-left: 7px;
          cursor: pointer;
          width: 230px;
          height: 90px;
          background: #9e9e9e;
          border-radius: 10px;
          text-align: right;
          padding-right: 10px;
          box-sizing: border-box;
          position: relative;
          line-height: 90px;
          font-weight: 600;
          color: #fff;
        }

        .score {
          position: absolute;
          top: -5px;
          left: -5px;
          height: 100px;
          width: 190px;
          text-align: center;
          line-height: 100px;
          background: #ccc;
          border-radius: 20px;
          margin-bottom: 40px;
          font-size: 30px;
          font-weight: 600;
          color: #fff;
        }

        .active {
          background: rgb(237, 2, 140);
        }
      }

      .anwser {
        margin-top: 30px;
        margin-left: 15px;
        width: 85%;
        box-sizing: border-box;
      }
    }
  }

  .left,
  .right {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 160px;
    height: 320px;
  }

  .left {
    left: -58px;

    img {
      width: 59px;
      height: 319px;
    }
  }

  .right {
    right: -61px;

    img {
      width: 161px;
      height: 320px;
    }
  }
}

.ifream-boxs {
  z-index: 10;
  position: absolute;
  box-shadow: 0 0 5px black;
  right: 3%;
  bottom: -33%;
  width: 46%;
  height: 40%;
  background: #fff;

  img {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translateX(-50%);
    width: 64px;
    height: 37px;
    z-index: 9999;
  }

  .isUp {
    transform: translateX(-50%) rotate(180deg);
  }
}

.toleft {
  animation: moveL 1s;
  left: 10%;
}

.toright {
  animation: moveR 1s;
  left: 89%;
}

.toUp {
  animation: moveUp 1s;
  bottom: 5%;
}

.toDown {
  animation: moveDown 1s;
  bottom: -33%;
}

@keyframes moveR {
  0% {
    left: 10%;
  }

  100% {
    left: 89%;
  }
}

@keyframes moveL {
  0% {
    left: 89%;
  }

  100% {
    left: 10%;
  }
}

@keyframes moveUp {
  0% {
    bottom: -33%;
  }

  100% {
    bottom: 5%;
  }
}

@keyframes moveDown {
  0% {
    bottom: 5%;
  }

  100% {
    bottom: -33%;
  }
}

@media (max-width: 640px) {
  .Scantron .contain .top .box .title {
    width: 60px;
  }

  .Scantron .contain .top .box .rights .btn {
    width: 45px;
    height: 25px;
    line-height: 25px;
  }

  .Scantron .contain .top .box .rights span {
    font-size: 20px;
  }

  // .Scantron .contain .top .box .time {
  //   width: 75px;
  //   height: 20px;
  // }
  .Scantron .contain .bottom {
    display: block;
    height: 93%;
  }

  .Scantron .contain .bottom .btns {
    flex-direction: unset;
    height: 60px;
  }

  .Scantron .contain .bottom .anwser {
    margin-left: unset;
    width: 100%;
    height: 100%;
  }

  .Scantron .contain .bottom .btns .item {
    width: 55px;
    height: 50px;
    flex-shrink: 0;
    margin-right: 7px;
    margin-left: 0;
    border-radius: 3px;
    line-height: 78px;
    text-align: center;
    font-size: 13px;
  }

  .Scantron .contain .bottom .btns .score {
    height: 30px;
    line-height: 30px;
    width: 55px;
    z-index: 999;
    top: 0;
    left: 0;
  }

  .ifream-boxs {
    right: 9%;
    width: 80%;
  }

  .ifream-boxs img {
    width: 30px;
    height: 24px;
  }
}
</style>
