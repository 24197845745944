// 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
}

// 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
// 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
if (navigator.mediaDevices.getUserMedia === undefined) {
    let getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
    navigator.mediaDevices.getUserMedia = function (constraints) {
        // 首先，如果有getUserMedia的话，就获得它

        // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
        if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }

        // 否则，为老的navigator.getUserMedia方法包裹一个Promise
        return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
        });
    }
}

//wav 能指定sampleBits和sampleRate
//mp3 不能指定sampleBits和sampleRate
let HZRecorder = function (stream, config) {
    config = config || {};
    config.format     = config.format || 'wav';
    config.sampleBits = config.sampleBits || 8;      //采样数位 8, 16
    config.sampleRate = config.sampleRate || (44100 / 6);   //采样率(1/6 44100)
    config.bitRate    = config.bitRate || 128;

    console.log('HZRecorder.config', config);


    //创建一个音频环境对象
    let audioContext = window.AudioContext || window.webkitAudioContext;
    let context = new audioContext();
    console.log('HZRecorder.context', context);

    //将声音输入这个对像
    let audioInput = context.createMediaStreamSource(stream);

    //设置音量节点
    let volume = context.createGain();
    audioInput.connect(volume);

    //创建缓存，用来缓存声音
    let bufferSize = 4096;

    // 创建声音的缓存节点，createScriptProcessor方法的
    // 第二个和第三个参数指的是输入和输出都是双声道。
    let recorder = context.createScriptProcessor(bufferSize, 2, 2);

    let audioData = {
        outputFormat: config.format || 'wav',
        samplesMono: null,
        maxSamples: 1152,
        // eslint-disable-next-line no-undef
        mp3Encoder: new lamejs.Mp3Encoder(1, context.sampleRate || 44100, config.bitRate || 128),
        dataBuffer: [],
        size: 0, // 录音文件长度
        buffer: [], // 录音缓存
        inputSampleRate: context.sampleRate, // 输入采样率
        inputSampleBits: 16, // 输入采样数位 8, 16
        outputSampleRate: config.sampleRate, // 输出采样率
        outputSampleBits: config.sampleBits, // 输出采样数位 8, 16

        convertBuffer: function(arrayBuffer) {
            let data = new Float32Array(arrayBuffer);
            let out = new Int16Array(arrayBuffer.length);
            this.floatTo16BitPCM(data, out);
            return out;
        },
        floatTo16BitPCM: function(input, output) {
            for (let i = 0; i < input.length; i++) {
                let s = Math.max(-1, Math.min(1, input[i]));
                output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
            }
        },
        appendToBuffer: function(mp3Buf) {
            this.dataBuffer.push(new Int8Array(mp3Buf));
        },
        inputMP3: function(arrayBuffer) {
            this.samplesMono = this.convertBuffer(arrayBuffer);
            let remaining = this.samplesMono.length;
            for (let i = 0; remaining >= 0; i += this.maxSamples) {
                let left = this.samplesMono.subarray(i, i + this.maxSamples);
                let mp3buf = this.mp3Encoder.encodeBuffer(left);
                this.appendToBuffer(mp3buf);
                remaining -= this.maxSamples;
            }
        },
        finishMP3: function() {
            this.appendToBuffer(this.mp3Encoder.flush());
            return new Blob(this.dataBuffer, { type: 'audio/mp3' });
        },

        inputWAV: function(data) {
            this.buffer.push(new Float32Array(data));
            this.size += data.length;
        },
        compress: function() {
            // 合并压缩
            // 合并
            let data = new Float32Array(this.size);
            let offset = 0;
            for (let i = 0; i < this.buffer.length; i++) {
                data.set(this.buffer[i], offset);
                offset += this.buffer[i].length;
            }
            // 压缩
            let compression = parseInt(this.inputSampleRate / this.outputSampleRate, 10);
            let length = data.length / compression;
            let result = new Float32Array(length);
            let index = 0;
            let j = 0;
            while (index < length) {
                result[index] = data[j];
                j += compression;
                index++;
            }
            return result;
        },
        finishWAV: function() {
            let sampleRate = Math.min(this.inputSampleRate, this.outputSampleRate);
            let sampleBits = Math.min(this.inputSampleBits, this.outputSampleBits);
            let bytes = this.compress();

            let dataLength = bytes.length * (sampleBits / 8);
            let buffer = new ArrayBuffer(44 + dataLength);
            let data = new DataView(buffer);

            let channelCount = 1; // 单声道
            let offset = 0;

            let writeString = function(str) {
                for (let i = 0; i < str.length; i++) {
                    data.setUint8(offset + i, str.charCodeAt(i));
                }
            };

            // 资源交换文件标识符
            writeString('RIFF');
            offset += 4;
            // 下个地址开始到文件尾总字节数,即文件大小-8
            data.setUint32(offset, 36 + dataLength, true);
            offset += 4;
            // WAV文件标志
            writeString('WAVE');
            offset += 4;
            // 波形格式标志
            writeString('fmt ');
            offset += 4;
            // 过滤字节,一般为 0x10 = 16
            data.setUint32(offset, 16, true);
            offset += 4;
            // 格式类别 (PCM形式采样数据)
            data.setUint16(offset, 1, true);
            offset += 2;
            // 通道数
            data.setUint16(offset, channelCount, true);
            offset += 2;
            // 采样率,每秒样本数,表示每个通道的播放速度
            data.setUint32(offset, sampleRate, true);
            offset += 4;
            // 波形数据传输率 (每秒平均字节数) 单声道×每秒数据位数×每样本数据位/8
            data.setUint32(offset, channelCount * sampleRate * (sampleBits / 8), true);
            offset += 4;
            // 快数据调整数 采样一次占用字节数 单声道×每样本的数据位数/8
            data.setUint16(offset, channelCount * (sampleBits / 8), true);
            offset += 2;
            // 每样本数据位数
            data.setUint16(offset, sampleBits, true);
            offset += 2;
            // 数据标识符
            writeString('data');
            offset += 4;
            // 采样数据总数,即数据总大小-44
            data.setUint32(offset, dataLength, true);
            offset += 4;
            // 写入采样数据
            if (sampleBits === 8) {
                for (let i = 0; i < bytes.length; i++, offset++) {
                    const s = Math.max(-1, Math.min(1, bytes[i]));
                    let val = s < 0 ? s * 0x8000 : s * 0x7fff;
                    val = parseInt(255 / (65535 / (val + 32768)), 10);
                    data.setInt8(offset, val, true);
                }
            } else {
                for (let i = 0; i < bytes.length; i++, offset += 2) {
                    const s = Math.max(-1, Math.min(1, bytes[i]));
                    data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
                }
            }

            return new Blob([data], { type: 'audio/wav' });
        },

        inputOrigData: function (data) {
            switch(this.outputFormat) {
                case 'wav': this.inputWAV(data); break;
                case 'mp3': this.inputMP3(data); break;
                default: break;
            }
        },
        clearBuffer: function() {
            this.dataBuffer = [];
            this.buffer = [];
            this.size = 0;
        }
    };

    //开始录音
    this.start = function () {
        audioInput.connect(recorder);
        recorder.connect(context.destination);
    };

    //停止
    this.stop = function () {
        recorder.disconnect();
    };

    // 结束
    this.end = function() {
        context.close();
    };

    // 继续
    this.again = function() {
        recorder.connect(context.destination);
    };

    //获取音频文件
    this.getMp3Blob = function () {
        this.stop();
        let blob= audioData.finishMP3();
        audioData.clearBuffer();
        return blob;
    };
    this.getWavBlob = function () {
        this.stop();
        let blob = audioData.finishWAV();
        audioData.clearBuffer();
        return blob;
    };

    //音频采集
    recorder.onaudioprocess = function (e) {
        audioData.inputOrigData(e.inputBuffer.getChannelData(0));
    };

};

//抛出异常
HZRecorder.throwError = function (message) {
    throw new function () { this.toString = function () { return message; };};
};
//是否支持录音
HZRecorder.canRecording = (navigator.getUserMedia != null);
//获取录音机
HZRecorder.get = function (callback, config) {
    if (callback) {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(function(stream) {
                let rec = new HZRecorder(stream, config);
                callback(rec);
            })
            .catch(function(error) {
                console.log('无法录音，请检查设备状态', error);
                HZRecorder.throwError('无法录音，请检查设备状态');
            });
    }
};
window.HZRecorder = HZRecorder;
