import axios from 'axios'
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库

// 创建axios的对象
const instance = axios.create({
    baseURL: "https://readapi.bluebirdabc.com/exam/en",  //配置固定域名
    timeout: 30 * 60 * 1000
})

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    (config) => {
        // config.headers.token = '123456' // 请求头添加token值
        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
    (response) => {
        return response.data
    },
    (err) => {
        return Promise.reject(err)
    }
)

//封装post请求
let post = function (url, data_ = {}) {
    return new Promise((resolve, reject) => {
        instance.post(url, data_).then((res) => {
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

//封装get请求
let get = function (url, params) {
    return new Promise((resolve, reject) => {
        instance.get(url, { params }).then((res) => {
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}


export default { post, get };