import { render, staticRenderFns } from "./HippoPaper.vue?vue&type=template&id=39699389&scoped=true"
import script from "./HippoPaper.vue?vue&type=script&lang=js"
export * from "./HippoPaper.vue?vue&type=script&lang=js"
import style0 from "./HippoPaper.vue?vue&type=style&index=0&id=39699389&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39699389",
  null
  
)

export default component.exports