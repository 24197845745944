
<template>
  <div v-if="bPaperLoaded" style="padding: 30px; position: relative" :style="containerStyle">
    <div style="width: 100%;position: relative;">
      <img src="@/assets/hippo-home/banner-finish.png" style="width: 100%;">
      <div class="num">{{donePaperSets}}</div>
	    <img class="freeBtn" @click="goUrl" src="@/assets/hippo-home/free.png">
      <img class="chargeBtn" @click="recharge.bShow=true" src="@/assets/hippo-home/charge.png">
      <div class="endDate">{{serviceEndDate}}</div>
    </div>
    <div v-for="(paperSet, name) of this.paperSets" style="background-color:rgb(180,220,220);border-radius:15px;">
      <div  style="display: flex; height:7vw; margin-top:10px;">
        <div style="position:relative;width:20%;padding-left:4%;display:flex;align-items:center;font-weight:bolder;font-size:2vw;position: relative;">{{name}}
          <img v-if="isAll(paperSet)" src="@/assets/hippo-home/all-baogao.png" @click="openBaogao(paperSet)" style="width: 80%;top: 50%;cursor: pointer;transform: translateY(-50%);position: absolute;">
        </div>
        <div style="position:relative;width:80%;display:flex;align-items:center;">
          <a v-for="i in [0,1,2]" target="_blank"
             style="position:absolute;width:33%;height:100%;display:block;"
             :style="{left:(i*33.333)+'%'}"
             :href="'/exam/#/?user_id='+params.user_id+'&pid='+params.pid+'&paper_id='+paperSet[linkPaperNames[i]].paper_id+'&examinee_id='+calculateExamineeId(paperSet[linkPaperNames[i]])+'&exam_name='+linkNames[i]">
            <div style="position:relative;left:0%;width:100%;height:100%">
              <img style="position:relative;left:0%;top:15%;width:99.5%;height:70%" :src="linkImages[i]">
              <img v-if="isDoneExaminee(paperSet[linkPaperNames[i]])" src="@/assets/hippo-home/done.png"
                   style="position:absolute;left:70%;bottom:20%;width:20%;">
            </div>
          </a>
        </div>
      </div>
    </div>
    <el-dialog
        title="报告"
        :visible.sync="baogaoVisible"
        custom-class="baogao-window"
        @close="baogaoVisible = false"
        width="60vh">
        <div class="baogao-wrap" :class="`${$route.query.exam_name}-bg`">
          <div class="wel1">亲爱的{{baogaoData.nickname}}同学，恭喜你完成了 当前的考试！</div>
          <div class="wel2">这是你在这份试卷中的能力统计：</div>
          <div class="ans1">与听力能力有关的题目一共{{baogaoData?.abilities?.listening?.total}}道，你做对了{{baogaoData?.abilities?.listening?.correct}}道，正确率为{{calc(baogaoData?.abilities?.listening?.correct,baogaoData?.abilities?.listening?.total)}}%</div>
          <div class="ans2">与阅读能力有关的题目一共{{baogaoData?.abilities?.reading?.total}}道，你做对了{{baogaoData?.abilities?.reading?.correct}}道，正确率为{{calc(baogaoData?.abilities?.reading?.correct,baogaoData?.abilities?.reading?.total)}}%</div>
          <div class="ans3">与词汇能力有关的题目一共{{baogaoData?.abilities?.vocabulary?.total}}道，你做对了{{baogaoData?.abilities?.vocabulary?.correct}}道，正确率为{{calc(baogaoData?.abilities?.vocabulary?.correct,baogaoData?.abilities?.vocabulary?.total)}}%</div>
          <div class="ans4">与语法能力有关的题目一共{{baogaoData?.abilities?.grammar?.total}}道，你做对了{{baogaoData?.abilities?.grammar?.correct}}道，正确率为{{calc(baogaoData?.abilities?.grammar?.correct,baogaoData?.abilities?.grammar?.total)}}%</div>
          <ul>
            <li class="level1">{{baogaoData?.abilities?.listening?.comment}}</li>
            <li class="level2">{{baogaoData?.abilities?.reading?.comment}}</li>
            <li class="level3">{{baogaoData?.abilities?.vocabulary?.comment}}</li>
            <li class="level4">{{baogaoData?.abilities?.grammar?.comment}}</li>
          </ul>
        </div>
    </el-dialog>
    <el-dialog title="充值" :visible.sync="recharge.bShow" @close="recharge.bShow=false" width="60vh">
      <div style="padding: 15px 50px 50px 50px;">
        <div>请输入12位卡号：</div>
        <div style="margin-top:5px;">
          <input type="text" style="width:60%;" v-model="recharge.cardNumber">
          <button style="margin-left:10px;" @click="onClickRecharge()">确认</button>
        </div>
        <!--div style="margin-top:10px;cursor:pointer;color:#C00000;" v-on:click="onClickGotoCardStore()">去商城购买充值卡 >></div-->
        <div style="margin-top:10px;">{{recharge.msg}}</div>
      </div>
    </el-dialog>
    <hippo-click-where></hippo-click-where>
  </div>
</template>

<script>
import {getPaperList, getExamineeReport, rechargeCardNumber, getServieEndDate} from "../../api/index";
import HippoClickWhere from "@/views/components/hippo-click-where.vue";

const PaperType_HippoListening  = 7;
const PaperType_Reading_Writing = 8;
const PaperType_Use_Of_English  = 9;

export default {
  components: {HippoClickWhere},
  data() {
    return {
      params: {
        user_id: 0,
        pid: ''
      },
      containerStyle: {},
      linkNames: [
        'Listening',
        'ReadingWriting',
        'UseOfEnglish'
      ],
      linkPaperNames: [
        'Listening',
        'Reading & Writing',
        'Use of English'
      ],
      linkImages: [
        require('@/assets/hippo-home/listening.png'),
        require('@/assets/hippo-home/Reading.png'),
        require('@/assets/hippo-home/Use.png'),
      ],
      paperSets: {
        'Paper 01':{
          'Listening': {},
          'Reading & Writing': {},
          'Use of English': {},
        },
      },
      donePaperSets: 0,
      bPaperLoaded: false,
      baogaoVisible: false,
      baogaoData:{},
      recharge: {
        bShow: false,
        cardNumber: '',
        msg: '',
      },
      serviceEndDate: '',
    };
  },

  created() {
	  document.title = "HIPPO 1 Online Test";
  },

  async mounted() {
    //enable scroll
    document.getElementById('app').style.overflow = 'scroll';

    //page scale
    if(window.innerWidth > window.innerHeight) {
      let width = (window.innerHeight / window.innerWidth)*100;
      let left = (100 - width)/2;
      this.containerStyle.width = width+'%';
      this.containerStyle.left = left+'%';
    }

    //save parameters and access-token
    this.handleParamsAndToken();

    //get service end-date
    await this.fetchHippoEndDate();

    //get paper lists
    await this.fetchPaperList(PaperType_HippoListening ); //7 Hippo Listening
    await this.fetchPaperList(PaperType_Reading_Writing); //8 Hippo Reading & Writing
    await this.fetchPaperList(PaperType_Use_Of_English ); //9 Hippo Use of English
    this.donePaperSets = this.calculateDonePaperSets();
    this.bPaperLoaded = true;
  },

  methods: {
    fetchHippoEndDate: async function () {
      const res = await getServieEndDate('HIPPO-A1');
      if(res && res.code == 0) {
        this.serviceEndDate = "服务有效期至："+res.data;
      }
    },
    onClickRecharge: async function () {
      if(!this.recharge.cardNumber || this.recharge.cardNumber.length<=0)
        return;
      const res = await rechargeCardNumber(this.recharge.cardNumber);
      if(res.code == 0) {
        let msg = '';
        if (res.data.cardType == "READING") {
          msg = ("阅读服务 充值成功");
        } else if(res.data.cardType == "ONLINE_QA"){
          msg = ("名师在线服务 充值成功");
        } else if(res.data.cardType == "READING_SPECIAL"){
          msg = ("扩展阅读 充值成功");
        } else if(res.data.cardType == "HIPPO_A1_EXAM"){
          msg = ("HIPPO A1考试 充值成功");
        } else if(res.data.cardType == "CEFR_A1_MOVER_EXAM") {
          msg = ("CEFR A1 Mover考试 充值成功");
        } else {
          msg = ("充值成功");
        }
        this.recharge.msg = msg + '，有效期至: '+res.data.serviceEndDate;
        this.serviceEndDate = "服务有效期至："+res.data.serviceEndDate;
      } else {
        this.recharge.msg = res.msg;
      }
    },
    onClickGotoCardStore: function () {
      let pid = localStorage.getItem('exam.pid');
      if(pid) {
        let url = '//readapi.bluebirdabc.com/partner/get/wxjsapiticket?pid=' + pid;
      }
    },

    handleParamsAndToken: function () {
      let bNeedHref = false;
      if(this.$route.query.user_id) {
        this.params.user_id = Number(this.$route.query.user_id); //用户id
        localStorage.setItem('exam.user_id', this.params.user_id);
        bNeedHref = true;
      } else {
        this.params.user_id = Number(localStorage.getItem('exam.user_id'));
      }

      if(this.$route.query.pid) {
        this.params.pid = this.$route.query.pid;  //合作方id
        localStorage.setItem('exam.pid', this.params.pid);
      } else {
        this.params.pid = localStorage.getItem('exam.pid');
      }

      if(this.$route.query.token) {
        this.params.token = this.$route.query.token;
        localStorage.setItem('exam.token', this.params.token);
        bNeedHref = true;
      } else {
        this.params.token = localStorage.getItem('exam.token');
      }

      if(bNeedHref) {
        let idx = window.location.href.indexOf('?');
        let href = window.location.href.substring(0, idx);
        href += '?pid='+this.params.pid;
//        let href = window.location.origin + '/#/hippo-home?pid='+this.params.pid; //地址栏隐藏user_id, token
        if(this.$route.query.debug) {
          href += '&debug='+this.$route.query.debug;
        }
        window.location.href = href;
      }
    },

    fetchPaperList: async function (paper_type_id) {
      const res = await getPaperList({
        user_id: this.params.user_id, //用户id
        pid: this.params.pid, //合作方id
        paper_type_id: paper_type_id
      });
      if (res.code == 0) {
        res.data.paper_list.sort((p1,p2)=>{return p1.paper_id-p2.paper_id;});
        for(let paper of res.data.paper_list) {
          let idx = paper.title.lastIndexOf('Paper');
          if(idx > 0) {
            let paperName = paper.title.substring(idx);
            if(!this.paperSets[paperName]) {
              this.paperSets[paperName] = {};
            }
            switch(paper_type_id) {
              case PaperType_HippoListening : this.paperSets[paperName]['Listening'        ] = paper; break;
              case PaperType_Reading_Writing: this.paperSets[paperName]['Reading & Writing'] = paper; break;
              case PaperType_Use_Of_English : this.paperSets[paperName]['Use of English'   ] = paper; break;
            }
          }
        }
      }
    },

    isDoneExaminee: function (paper) {
      if(paper.doing_examinee)
        return false;
      if(paper.examinees && paper.examinees.length>0)
        return paper.examinees[0].status === 'done';
      return false;
    },
    calculateExamineeId: function (paper) {
      //如果有未完成的考试，进入此考试
      if(paper.doing_examinee)
        return paper.doing_examinee.examinee_id;

      //如果有已完成的考试，则进入此考试
      if(paper.examinees && paper.examinees.length>0)
        return paper.examinees[0].examinee_id;

      //都没有，则开启新的考试
      return 0;
    },
    calculateDonePaperSets: function () {
      let n = 0;
      for(let name in this.paperSets) {
        let paperSet = this.paperSets[name];
        if(this.isDoneExaminee(paperSet[this.linkPaperNames[0]]) &&
            this.isDoneExaminee(paperSet[this.linkPaperNames[1]]) &&
            this.isDoneExaminee(paperSet[this.linkPaperNames[2]])) {
          n++;
        }
      }
      return n;
    },
    goUrl(){
//  		window.open('https://exam.bluebirdabc.com/#/?user_id='+this.$route.query.user_id+'&pid=aif8j9q38t4q78y4&paper_id=253&examinee_id=0')
      window.open('/exam/#/?user_id='+this.params.user_id+'&pid=aif8j9q38t4q78y4&paper_id=253&examinee_id=0')
    },
    isAll(paperSet){
        return this.isDoneExaminee(paperSet[this.linkPaperNames[0]]) &&
          this.isDoneExaminee(paperSet[this.linkPaperNames[1]]) &&
          this.isDoneExaminee(paperSet[this.linkPaperNames[2]])
    },
    //获取报告
    async openBaogao(paperSet){
      this.baogaoVisible = true;
      const res = await getExamineeReport({
        user_id: Number(this.$route.query.user_id), //用户id, //用户id
        pid: this.$route.query.pid, //合作方id
        examinee_id_list: [this.calculateExamineeId(paperSet[this.linkPaperNames[0]]),this.calculateExamineeId(paperSet[this.linkPaperNames[1]]),this.calculateExamineeId(paperSet[this.linkPaperNames[2]])], //考试场次id
      });
      this.baogaoData = res.data
      const abilitiesObject = this.baogaoData.abilities.reduce((acc, current) => {
        acc[current.ability] = current;
        return acc;
      }, {});
      this.baogaoData.abilities = abilitiesObject;
      console.dir(this.baogaoData)
    },
    calc(num1,num2){
      return parseInt(num1/num2*100)
    },
  }
}
</script>

<style lang="less" scoped>
.warning-text{
	border: 2px solid #B4DCDC;
	padding: 10px 20px;
	line-height: 60px;
	font-size: 42px;
	div{
		color: #C00000;
		font-weight: bold;
		text-align: center;
	}
}
.freeBtn{
	position: absolute;
	height: 4.2%;
	right: 0;
	top:20%;
	cursor: pointer;
	@media screen and (min-width: 0px) and (max-width: 540px) {
		width:24vw;
		height:4.8vw;
	}
}
.chargeBtn{
  position: absolute;
  height: 4.2%;
  right: 21%;
  top:20%;
  cursor: pointer;
}
.endDate{
  position: absolute;
  right: 0%;
  top:24.5%;
  font-size: small;
  color: #C00000;
}
.num{
	position:absolute;
	right:14%;
	top: 9%;
	font-weight: bolder;
	font-size:3vw;
	@media screen and (min-width: 0px) and (max-width: 540px) {
		font-size:4vw;
		top: 9.4%;
	}
}
.baogao-wrap{
	width:60vh;
	height:90vh;
	background-image: url('../../assets/hippo-home/overall-UI.png');
	background-size: 100% 100%;
	position: relative;
	font-size: 1.5vh;
	color: #000;
	div{
		width: 100%;
		position: absolute;
		text-align: left;
		left: 8vh;
	}
	ul{
		overflow-y: auto;
		position: absolute;
		list-style-type: circle;
		width: 45vh;
		left: 8vh;
		height: 16vh;
		top:65vh;
		li{
			margin: 10px;
		}
	}
	.ans1{
		top: 40vh;
	}
	.ans2{
		top: 46vh;
	}
	.ans3{
		top: 52vh;
	}
	.ans4{
		top: 57.6vh;
	}
	.wel1{
		top: 31vh;
		left: 0;
		text-align: center;
	}
	.wel2{
		top: 33.7vh;
		left: 0;
		text-align: center;
	}
}
@media screen and (min-width: 0px) and (max-width: 540px) {
	.baogao-wrap{
		width:98vw;
		height:150vw;
		font-size: 3vw;
		line-height: 3.2vw;
		div{
			left: 5vw;
		}
		ul{
			overflow-y: auto;
			position: absolute;
			list-style-type: circle;
			width: 70vw;
			left: 12vw;
			height: 30vw;
			top:108vw;
			li{
				margin: 10px;
			}
		}
		.ans1{
			width: 60vw;
			left: 15vw;
			top: 65.5vw;
		}
		.ans2{
			width: 60vw;
			left: 15vw;
			top: 75.5vw;
		}
		.ans3{
			width: 60vw;
			left: 15vw;
			top: 85.7vw;
		}
		.ans4{
			width: 60vw;
			left: 15vw;
			top: 95.2vw;
		}
		.wel1{
			top: 53vw;
			left: 0;
			text-align: center;
		}
		.wel2{
			top: 57vw;
			left: 0;
			text-align: center;
		}
	}
}
::v-deep .el-dialog__headerbtn{
	@media screen and (min-width: 0px) and (max-width: 540px) {
		font-size: 5vw;
	}
}
::v-deep .el-dialog{
	margin-top: 20px!important;
	@media screen and (min-width: 0px) and (max-width: 540px) {
		width:98vw!important;
		margin-top: 50px!important;
		margin-left: 1vw!important;
	}
}
::v-deep .el-dialog__body{
	padding: 0;
}
</style>


<!--
(listening-- <60%)你的听力还需要加强练习，强烈建议你去报名低一级别或同等级的听力课程，目前挑战A1级别的难度很大。
(listening-- 61%-80%)你的听力还需要加强练习，强烈建议你去报名同等级的听力课程，目前挑战A1级别有一点难度。
(listening-- 81%-90%)你的听力水平还不错，平时多加练习，多做几套卷子，挑战A1级别不是问题。
(listening-- 91%-100%)你的听力水平还不错，平时多加练习，多做几套卷子，可以尝试挑战比A1更高的级别。

(reading-- <60%)你的阅读还需要多练，根据当前的水平，你应该报名低一级别或同等级的阅读课程，你当前与A1级别还有一定的距离。
(reading-- 61%-80%)你的阅读还需要多练，根据当前的水平，你应该报名同等级的阅读课程，现在挑战A1级别有点勉强。
(reading-- 81%-90%)你的阅读能力还不错，平时保持一定的阅读量，再多做几套卷子，A1对你来说不是问题。
(reading-- 91%-100%)你的阅读水平还不错，平时保持一定的阅读量，多做几套卷子，A1已经不是你的障碍，至少在阅读上你可以挑战更高的级别。

(vocabulary-- <60%)你的词汇问题不小。以考试的结果来看，你应该报名低一级别或同等级的词汇课程，平时加强词汇背诵，注意要理解记忆，而不是死记硬背，以现在的词汇量达不到A1级别的要求。
(vocabulary-- 61%-80%)你的词汇问题不小。以考试的结果来看，你应该报名同等级的词汇课程，平时加强词汇背诵，注意要理解记忆，而不是死记硬背，以现在的词汇量，要顺利通过A1级别的考试有一定的困难。
(vocabulary-- 81%-90%)你的词汇问题不大。以考试的结果来看，你的词汇能力还不错，注意要理解记忆，而不是死记硬背，单独看词汇方面，通过A1级别不是问题。
(vocabulary-- 91%-100%)你的词汇非常棒。以考试的结果来看，你应该平时也很注重词汇方面的练习，注意要理解记忆，而不是死记硬背，单独看词汇方面，你可以尝试比A1更高级别的考试。

(grammar-- <60%)你的语法不太好。根据考试的情况，你应该报名低一级别或同等级的语法课程，平时加强语法的练习，注意对语法点的理解和运用，你现在的语法水平不满足A1级别的要求。
(grammar-- 61%-80%)你的语法水平一般。根据考试的情况，你应该报名低一级同等级的语法课程，平时加强语法的练习，注意对语法点的理解和运用，你现在的语法水平要通过A1级别不容易。
(grammar-- 81%-90%)你的语法很好。根据考试的情况，你的语法能力还不错，可以多做几套卷子，多多练习，单独看语法方面，A1级别已经难不倒你。
(grammar-- 91%-100%)你的语法真不错。根据考试的情况，你已经充分掌握了A1这个难度的语法，单独看语法水平，你可以挑战比A1更高级别的考试。

括号内是参数标准，不要体现在界面上。
四个部分各选择匹配参数的一项
-->
