<template>
  <div
    id="source"
    draggable="true"
    @mousedown="dragStart"
    @mousemove="dragMove"
    @mouseup="dragEnd"
    @dragstart="dragStart"
    @dragmove="dragMove"
    @dragend="dragEnd"
    @touchstart="dragStart"
    @touchmove="dragMove"
    @touchend="dragEnd"
    @click="jumpToLiveClass"
    style="z-index: 10; display: flex; align-items: center; position: fixed;"
    :style="'top:' + top + ';left:' + left + ';'"
  >
    <img src="@/assets/hippo-home/cust-service.png" style="width:130px;" />
  </div>
</template>

<script setup>
import { ref } from "vue";

let isDragging = false;
let diffX = 0;
let diffY = 0;
let top = ref("30%");
let left = ref("70%");
if(window.innerWidth > window.innerHeight) {
  top = ref('38%');
} else {
  top = ref('26%');
}

let clickTimeout;
const jumpToLiveClass = () => {
  window.open('https://readapi.bluebirdabc.com/lessonplayer/index.html?userId=1111&pid=&lessonId=206&auto=true');
};
const dragStart = (e) => {
  e.preventDefault();
  isDragging = false;
  clickTimeout = setTimeout(() => {
    isDragging = true;
  }, 200); // 设置一个阈值，例如 200 毫秒
  let ele = document.getElementById("source");
  if (e.type == "touchstart") {
    let _e = e.changedTouches[0];
    diffX = _e.clientX - ele.offsetLeft;
    diffY = _e.clientY - ele.offsetTop;
  } else if (e.type == "mousedown") {
    diffX = e.clientX - ele.offsetLeft;
    diffY = e.clientY - ele.offsetTop;
  }
};

const dragMove = (e) => {
  if (!isDragging) return;
  e.preventDefault();
  if (e.type == "touchmove") {
    let _e = e.changedTouches[0];
    left.value = _e.clientX - diffX + "px";
    top.value = _e.clientY - diffY + "px";
  } else if (e.type == "mousemove") {
    left.value = e.clientX - diffX + "px";
    top.value = e.clientY - diffY + "px";
  }
};
const dragEnd = () => {
  clearTimeout(clickTimeout);
  if (!isDragging) {
    jumpToLiveClass();
  }
  isDragging = false;
};
</script>

<style>
</style>
