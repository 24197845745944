var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hearing-box"},[(!_vm.$store.state.examination)?_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.handleClick}},[_vm._v(_vm._s(_vm.btntext))]):_vm._e(),(_vm.$store.state.examination)?_c('div',{staticClass:"score"},[_c('span',[_vm._v(_vm._s(_vm.value ? _vm.value.score : ""))]),_c('p',[_vm._v("正确："+_vm._s(_vm.value ? _vm.value.right : "")+"题")]),_c('p',[_vm._v("错误："+_vm._s(_vm.value ? _vm.value.error : "")+"题")]),_c('p',[_vm._v("正确率："+_vm._s(_vm.value ? _vm.value.accuracy : "")+"%")]),_c('p',[_vm._v("耗时："+_vm._s(_vm.value ? _vm.value.elapsed : "")+"分钟")])]):_vm._e(),_c('div',{staticClass:"hearing"},_vm._l((_vm.arr),function(value,key){return _c('div',{staticClass:"list"},[_c('div',{staticClass:"second_title"},[_vm._v(_vm._s(key))]),_vm._l((value),function(item,i){return _c('div',{key:i,staticClass:"item"},[_c('span',[_vm._v(_vm._s(item.question_number))]),_c('el-radio-group',{attrs:{"disabled":_vm.isStart},model:{value:(_vm.answerList[`${item.id}`]),callback:function ($$v) {_vm.$set(_vm.answerList, `${item.id}`, $$v)},expression:"answerList[`${item.id}`]"}},_vm._l((_vm.list(item)),function(items){return _c('el-radio',{key:items,class:item.my_answer && _vm.$store.state.examination
		        ? item.my_answer == item.answer && item.my_answer === items
		          ? 'rights'
		          : item.my_answer === items
		            ? 'wrong'
		            : item.answer === items
		              ? 'rights'
		              : ''
		        : '',attrs:{"label":items}},[_vm._v(_vm._s(items))])}),1)],1)})],2)}),0),_vm._l((_vm.audioList),function(item){return _c('audio',{key:item,ref:"audios",refInFor:true,staticStyle:{"display":"none"},attrs:{"src":item}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }