<template>
  <div v-html="renderedMarkdown"></div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import MarkdownItKatex from 'markdown-it-katex';
import 'katex/dist/katex.min.css';

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      renderedMarkdown: '',
    };
  },
  watch: {
    content: {
      immediate: true,
      handler(val) {
        this.renderMarkdown(val);
      },
    },
  },
  methods: {
    renderMarkdown(content) {
      const md = new MarkdownIt();
      md.use(MarkdownItKatex);
      this.renderedMarkdown = md.render(content);
    },
  },
};
</script>

<style>
/* 如果需要自定义样式，可以在这里添加 */
</style>
