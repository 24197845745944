import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import HomeView from '../views/page/HomeView.vue'
//import child from '../views/page/HippoPaper.vue'
import HippoHome from "@/views/page/HippoHome.vue";
import A1MoverHome from "@/views/page/A1MoverHome.vue";
import HunanHome from "@/views/page/HunanHome.vue";
import KetPaper from "@/views/page/KetPaper.vue";
import CommonHome from "@/views/page/CommonHome.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView
  },
//  {
//    path: '/hippo-paper',
//    name: 'hippo-paper',
//    component: hippo-paper
//  },
  {
    path: '/hippo-home',
    name: 'hippo-home',
    component: HippoHome
  },
  {
    path: '/a1-mover',
    name: 'a1-mover',
    component: A1MoverHome
  },
  {
    path: '/hunan-home',
    name: 'hunan-home',
    component: HunanHome
  },
  {
    path: '/KetPaper',
    name: 'KetPaper',
    component: KetPaper
  },
  {
    path: '/home',
    name: 'home',
    component: CommonHome
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
